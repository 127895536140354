import http from './http.service';

const _path = '';

function getPath(dataObj){
   return _path+dataObj;
}

function filterByObject(target, filterObj) {
   if (typeof filterObj === 'string') {
      return target.filter(v => {
         return (JSON.stringify(v).toLowerCase().indexOf(filterObj.toLowerCase()) >= 0);
      });
   }

   let filterKeys = Object.keys(filterObj);
   let textIndex = filterKeys.indexOf('text');
   let text;
   if (textIndex >= 0) {
      text = filterObj['text'];
      filterKeys.splice(textIndex, 1);
   }
   return target.filter(v => {
      let allTrue = true;
      filterKeys.forEach(k => {
         if (Array.isArray(filterObj[k])) {
            allTrue = allTrue && (filterObj[k].indexOf(v[k]) >= 0)
         }
         else {
            allTrue = allTrue && (v[k] === filterObj[k]);
         }

         if (text) {
            allTrue = allTrue && (JSON.stringify(v).toLowerCase().indexOf(text.toLowerCase()) >= 0);
         }
      });
      return allTrue;
   });

}


function search(dataObj, params) {
   const lookups = window.getApp.$store.state.company.lookups;

   if (lookups && lookups[dataObj]) {
      console.log('From lookups: ', dataObj);
      let lkpData = (params) ? filterByObject(lookups[dataObj], params) : lookups[dataObj];
      return Promise.resolve(lkpData);
   }
   
   return http.request(getPath(dataObj), params);
}

function getById(dataObj, dataKey) {
   return http.request(`${getPath(dataObj)}/${dataKey}`);
}

function getData(dataObj, params, dataKey) {
   let path = getPath(dataObj);

   if (dataKey) {
      path += `/${dataKey}`;
   }

   return http.request(path, params);
}

function save(dataObj, data, dataKey) {
   let path = getPath(dataObj);
   
   if (dataKey) {
      path += `/${dataKey}`;
      return http.put(path, data);
   }

   return http.post(path, data);

}

function patch(dataObj, data){

   return http.patch(dataObj, data);

}

function destroy(dataObj, dataKey) {
   return http.destroy(`${getPath(dataObj)}/${dataKey}`);
}

function execute(dataObj, params, dataKey) {
   let path = getPath(dataObj);

   if (dataKey) {
      path += `/${dataKey}`;
      return http.put(path, params);
   }

   return http.post(path, params);
}

function postData (dataObj, params) {
   let path = getPath(dataObj);
   return http.post(path, params);
}

function postNotJson (dataObj, params) {
   let path = getPath(dataObj);
   return http.post(path, params, true);
}

function getPublicData(dataObj, params, dataKey) {
   let path = '/'+dataObj;
   return http.post(path, params);
}

export default {
   search,
   getById,
   getData,
   save,
   destroy,
   execute,
   patch,
   postData,
   postNotJson,
   getPublicData
};


