<template>
	
	<!-- Layout Footer -->
	<a-layout-footer>
		<a-row type="flex">
			<a-col :span="24" :md="12">

				<!-- Footer Copyright Notice -->
				<p class="copyright">
					© 2023 <a href="https://site.kemosoft.com.br/">Kemo</a>
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
			<a-col :span="24" :md="12" class="footer-control">

				<!-- Footer Navigation Menu -->
				<a-menu mode="horizontal">
					<!-- <a-menu-item><a href="https://creative-tim.com/" target="_blank">Creative Tim</a></a-menu-item>
					<a-menu-item><a href="https://www.creative-tim.com/presentation" target="_blank">About Us</a></a-menu-item>
					<a-menu-item><a href="https://www.creative-tim.com/blog" target="_blank">Blog</a></a-menu-item> -->
					<a-menu-item><a href="https://www.creative-tim.com/license" target="_blank">Ajuda</a></a-menu-item>
				</a-menu>
				<!-- / Footer Navigation Menu -->

			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>
