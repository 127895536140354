import http from './http.service';

export default {

   login (credentials) {
      return http.post('/login', credentials).then((data) => {
         console.log('Logged User:', data);
         window.getApp.$store.dispatch('company/stRegisterCompany', data);
         /*try {
            window.getApp.$store.dispatch('resetPageFilters');
         } catch (e) {
            //no action needed;
            console.error('resetPageFilters not implemented on store.');
         }*/

         return data;
      });
   },

   loginAffiliate (credentials) {

      return http.post('/auth/login', credentials).then((data) => {

            if(window.location.hash === '#/business/login'){
               localStorage.setItem('accessMode', 'business');
            }

         localStorage.setItem('accessToken', data.accessToken);
         
         return data;
      });
   },

   redirectGoogle(code){
      return http.rawGet(`auth/google/redirect?code=${code}`);
   },

   registerAffiliate(data) {
      return http.post('/signup/affiliate/register', data);
   },

   forgotPasswordAffiliate(credentials) {
      return http.post('signup/affiliate/forgotPasswordAffiliate', credentials);
   },

   forgotPasswordAdmin(credentials) {
      return http.post('signup/app/forgotPasswordAdmin', credentials);
   },

   changePassword (credentials) {
      let user = window.getApp.$store.state.company.user.id;
      return http.put('/ds/employee/changePin/' + user, credentials);
   },

   async logout () {
      let params = {user: window.localStorage.getItem('stAuthToken')};
      try{
         await http.post('/logout', params);
         console.log('LOGGED OUT On SERVER');
         window.localStorage.setItem('stAuthToken', '');
         window.getApp.$store.dispatch('company/stSetPinChanged');
      }catch(err){
         return false;
      }
      return true;
   },

   async logoutAffiliate () {
      let params = {user: window.localStorage.getItem('stAuthToken')};
      try{
         await http.post('/logout/affiliate', params);
         console.log('LOGGED OUT On SERVER');
         window.localStorage.setItem('stAuthToken', '');
         window.getApp.$store.dispatch('company/stSetPinChanged');
      }catch(err){
         return false;
      }
      return true;
   }

};




