<template>
  <!-- Main Sidebar -->
  <component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">
    <!-- Layout Header -->
    <a-layout-header style="margin: 0">
      <a-card class="header-menu">
        <a-row type="flex">
          <!-- MENU HAMBURGUER -->
          <a-col
            :span="7"
            style="justify-content: left"
            class="sidebar-toggler-col"
          >
            <!-- / Header Page Title -->
            <a-button
              type="link"
              class="sidebar-toggler"
              @click="$emit('minimizeSidebar'), resizeEventHandler()"
            >
              <svg
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                />
              </svg>
            </a-button>
          </a-col>

          <!--  RETIRADO 
        <a-col :span="20" :md="6">
          //CAMINHO DA PÁGINA 
          <a-breadcrumb>
            <template v-for="(item, key) in $route.meta.breadcrumbs">
              <a-breadcrumb-item
                v-if="key == $route.meta.breadcrumbs.length - 1"
                :key="key"
                >{{ item }}</a-breadcrumb-item
              >
              <a-breadcrumb-item v-else :key="key"
                ><router-link to="/">{{ item }}</router-link></a-breadcrumb-item
              >
            </template>
          </a-breadcrumb> 
          
          //NOME DA PÁGINA 
          <div class="ant-page-header-heading">
            <span class="ant-page-header-heading-title">{{
              this.$route.meta.title || this.$route.name
            }}</span>
          </div> 
        </a-col>  -->

          <!-- AVATAR -->
          <a-col :span="17" class="header-control">
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <img v-if="me" :src="me.userAccess.avatar" style="max-width: 37px; border-radius: 50px;">
                <img v-else src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzPb_pSj-ir-9eB6mi0lVJdQP1KKHiB8fRBS1CbmOXGd9Z1FEGMJHbEKhahwhWLGSaEXY&usqp=CAU" style="max-width: 37px; border-radius: 50px;">
              </a>
              <a-menu slot="overlay">
                <!-- MEU PERFIL -->
                <!-- <a-menu-item key="1" @click="goToProfile">
                  <a-icon type="user" />Meu Perfil
                </a-menu-item> -->
                <!-- CONFIGURAÇÕES -->
                <!-- <a-menu-item key="2" @click="goToSettings">
                  <a-icon type="setting" />Configurações
                </a-menu-item> -->
                <a-menu-item />
                <!-- SAIR -->
                <a-menu-item style="margin-top: -10px;">
                  <span style="font-size: smaller; font-weight: 600;">{{ me ? me.userAccess.name : 'Convidado' }}</span>
                </a-menu-item>
                <a-menu-item
                  key="3"
                  style="color: #595959; padding-left: 1rem"
                  @click="handleLogout"
                >
                  Sair
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <!-- NOTIFICAÇÕES -->
            <!-- <a-dropdown
            :trigger="['click']"
            overlayClassName="header-notifications-dropdown"
            :getPopupContainer="() => wrapper"
          >
            <a-badge count="4">
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
                    fill="#111827"
                  />
                  <path
                    d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
                    fill="#111827"
                  />
                </svg>
              </a>
            </a-badge>

            <a-list
              item-layout="horizontal"
              class="header-notifications-list"
              :data-source="notificationsData"
              slot="overlay"
            >
              <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta>
                  <template #description>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
                        fill="#111827"
                      />
                    </svg>
                    <span>{{ item.time }}</span>
                  </template>
                  <a slot="title" href="#">{{ item.title }}</a>
                  <a-avatar
                    v-if="item.img"
                    slot="avatar"
                    shape="square"
                    :src="item.img"
                  />
                  <a-avatar
                    v-else
                    shape="square"
                    slot="avatar"
                    v-html="item.svg"
                  />
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-dropdown>
 -->

            <a-button
              type="link"
              class="sidebar-toggler"
              @click="
                $emit('toggleSidebar', !sidebarCollapsed), resizeEventHandler()
              "
            >
              <svg
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="#FF0000"
              >
                <path
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                />
              </svg>
            </a-button>

            <!-- / Header Control Buttons -->

            <!-- PESQUISA -->
            <!-- <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Digite Aqui…" @search="onSearch" :loading='searchLoading'>
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search> -->
          </a-col>
          <!-- / Header Control Column -->
        </a-row>
      </a-card>
    </a-layout-header>
    <!--  /Layout Header -->
  </component>
  <!-- / Main Sidebar -->
</template>

<script>
const notificationsData = [
  {
    title: "New message from Sophie",
    img: "images/face-1.jpg",
    time: "13 minutes ago",
  },
  {
    title: "New album by Travis Scott",
    svg: `<svg width="20" height="20" viewBox="0 0 107 107" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<title>logo-spotify</title>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="logo-spotify" fill="#2EBD59" fill-rule="nonzero">
							<path d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z" id="Shape"></path>
						</g>
					</g>
				</svg>`,
    time: "1 day ago",
  },
  {
    title: "Payment completed",
    svg: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill="#1890FF" d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"/>
					<path fill="#1890FF" fill-rule="evenodd" clip-rule="evenodd" d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"/>
				</svg>`,
    time: "2 days ago",
  },
];

export default {
  props: {
    // Header fixed status.
    navbarFixed: {
      type: Boolean,
      default: false,
    },

    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Header notifications data.
    notificationsData: {
      type: Array,
      default: () => notificationsData,
    },
  },
  data() {
    return {
      // Fixed header/sidebar-footer ( Affix component ) top offset.
      top: 0,
      // The wrapper element to attach dropdowns to.
      wrapper: document.body,
      me: null,
    };
  },
  methods: {
    getProfile(){

      this.$api.dataService
        .getData('member/me')
        .then((res) => {
          console.log(res);
          this.me = res;
        })
        .catch((error) => {
          console.error("Erro ao obter informações do usuário:", error);
        });

    },
    resizeEventHandler() {
      this.top = this.top ? 0 : -0.01;
    },
    goToProfile() {
      this.$router.push("/profile");
    },
    goToSettings() {
      this.$router.push("/settings");
    },
    handleLogout() {
      localStorage.removeItem("accessToken");
      localStorage.clear();
      window.location = window.location.origin;
    },
  },
  mounted: function () {
    // Set the wrapper to the proper element, layout wrapper.
    this.wrapper = document.getElementById("layout-dashboard");
  },
  created() {
    // Registering window resize event listener to fix affix elements size
    // error while resizing.
    window.addEventListener("resize", this.resizeEventHandler);
    this.getProfile();

  },
  destroyed() {
    // Removing window resize event listener.
    window.removeEventListener("resize", this.resizeEventHandler);
  },
};
</script>

