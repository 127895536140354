<template>
  <!-- Main Sidebar -->
  <a-layout-sider collapsible class="sider-primary" breakpoint="lg" collapsed-width="0" width="250px"
    :collapsed="sidebarCollapsed" @collapse="$emit('toggleSidebar', !sidebarCollapsed)" :trigger="null" :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]" theme="light" :style="{ backgroundColor: 'transparent' }">
    <div class="brand" style="padding: 1rem">

      <img src="/images/logo.png" style="width: 35px; margin-top: -4px;">

      <span class="text-logo">Aliga</span>
    </div>

    <!-- MENU PRINCIPAIS -->
    <a-menu theme="light" mode="inline" :open-keys="openKeys" @openChange="onOpenChange">
      <!-- DASHBOARD MEMBERS/PRODUCERS -->
      <a-menu-item @click="handleMenuClick">
        <router-link to="/dashboards/member">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328z" />
            </svg>
          </span>
          <span class="label">Dashboard</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

      <!-- MARKETPLACE CONTEUDOS -->
      <!-- <a-menu-item v-if="isMember"> -->
      <a-menu-item v-if="isMember" @click="handleMenuClick">
        <router-link to="/pages/offer/members/list">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z" />
            </svg>
          </span>
          <span class="label">Marketplace</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

      <!-- OFERTAS PARA BUSINESS -->
      <a-menu-item v-if="isProducer" @click="handleMenuClick">
        <router-link to="/productor/contents">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M48 115.8C38.2 107 32 94.2 32 80c0-26.5 21.5-48 48-48c14.2 0 27 6.2 35.8 16H460.2c8.8-9.8 21.6-16 35.8-16c26.5 0 48 21.5 48 48c0 14.2-6.2 27-16 35.8V396.2c9.8 8.8 16 21.6 16 35.8c0 26.5-21.5 48-48 48c-14.2 0-27-6.2-35.8-16H115.8c-8.8 9.8-21.6 16-35.8 16c-26.5 0-48-21.5-48-48c0-14.2 6.2-27 16-35.8V115.8zM125.3 96c-4.8 13.6-15.6 24.4-29.3 29.3V386.7c13.6 4.8 24.4 15.6 29.3 29.3H450.7c4.8-13.6 15.6-24.4 29.3-29.3V125.3c-13.6-4.8-24.4-15.6-29.3-29.3H125.3zm2.7 64c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160zM256 320h32c35.3 0 64-28.7 64-64V224h64c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V320z" />
            </svg>
          </span>
          <span class="label">Minhas Produções</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

        <!-- OFERTAS PARA BUSINESS -->
        <a-menu-item v-if="isProducer" @click="handleMenuClick">
        <router-link to="/productor/financial/">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M48 115.8C38.2 107 32 94.2 32 80c0-26.5 21.5-48 48-48c14.2 0 27 6.2 35.8 16H460.2c8.8-9.8 21.6-16 35.8-16c26.5 0 48 21.5 48 48c0 14.2-6.2 27-16 35.8V396.2c9.8 8.8 16 21.6 16 35.8c0 26.5-21.5 48-48 48c-14.2 0-27-6.2-35.8-16H115.8c-8.8 9.8-21.6 16-35.8 16c-26.5 0-48-21.5-48-48c0-14.2 6.2-27 16-35.8V115.8zM125.3 96c-4.8 13.6-15.6 24.4-29.3 29.3V386.7c13.6 4.8 24.4 15.6 29.3 29.3H450.7c4.8-13.6 15.6-24.4 29.3-29.3V125.3c-13.6-4.8-24.4-15.6-29.3-29.3H125.3zm2.7 64c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160zM256 320h32c35.3 0 64-28.7 64-64V224h64c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V320z" />
            </svg>
          </span>
          <span class="label">Financeiro</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

      <!-- OFERTAS PARA BUSINESS -->
      <a-menu-item v-if="isProducer" @click="handleMenuClick">
        <router-link to="/productor/reports">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M48 115.8C38.2 107 32 94.2 32 80c0-26.5 21.5-48 48-48c14.2 0 27 6.2 35.8 16H460.2c8.8-9.8 21.6-16 35.8-16c26.5 0 48 21.5 48 48c0 14.2-6.2 27-16 35.8V396.2c9.8 8.8 16 21.6 16 35.8c0 26.5-21.5 48-48 48c-14.2 0-27-6.2-35.8-16H115.8c-8.8 9.8-21.6 16-35.8 16c-26.5 0-48-21.5-48-48c0-14.2 6.2-27 16-35.8V115.8zM125.3 96c-4.8 13.6-15.6 24.4-29.3 29.3V386.7c13.6 4.8 24.4 15.6 29.3 29.3H450.7c4.8-13.6 15.6-24.4 29.3-29.3V125.3c-13.6-4.8-24.4-15.6-29.3-29.3H125.3zm2.7 64c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160zM256 320h32c35.3 0 64-28.7 64-64V224h64c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V320z" />
            </svg>
          </span>
          <span class="label">Relatórios</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

      <!-- OFERTAS PARA MEMBRO -->
      <a-menu-item v-if="isMember" @click="handleMenuClick">
        <router-link to="/member/contents">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
              viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M48 115.8C38.2 107 32 94.2 32 80c0-26.5 21.5-48 48-48c14.2 0 27 6.2 35.8 16H460.2c8.8-9.8 21.6-16 35.8-16c26.5 0 48 21.5 48 48c0 14.2-6.2 27-16 35.8V396.2c9.8 8.8 16 21.6 16 35.8c0 26.5-21.5 48-48 48c-14.2 0-27-6.2-35.8-16H115.8c-8.8 9.8-21.6 16-35.8 16c-26.5 0-48-21.5-48-48c0-14.2 6.2-27 16-35.8V115.8zM125.3 96c-4.8 13.6-15.6 24.4-29.3 29.3V386.7c13.6 4.8 24.4 15.6 29.3 29.3H450.7c4.8-13.6 15.6-24.4 29.3-29.3V125.3c-13.6-4.8-24.4-15.6-29.3-29.3H125.3zm2.7 64c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160zM256 320h32c35.3 0 64-28.7 64-64V224h64c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V320z" />
            </svg>
          </span>
          <span class="label">Meus Conteúdos</span>
        </router-link>
      </a-menu-item>
      <!-- ==== -->

      <a-menu-item v-if="isMember" @click="handleMenuClick"> 
        <router-link to="/indications">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path
                d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z" />
            </svg>
          </span>
          <span class="label">Indicações</span>
        </router-link>
      </a-menu-item>

    </a-menu>

    <!-- FOOTER -->
    <!-- <a-menu>
      <a-menu-item>
        <router-link to="/pages/help">
          <span class="icon">
            <a-icon type="question-circle" theme="filled" class="m-0" />
          </span>
          <span class="label">Ajuda</span>
        </router-link>
      </a-menu-item>
    </a-menu> -->
  </a-layout-sider>
</template>

<script>
export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      isMember: localStorage.getItem("userType") === "member",
      isProducer: localStorage.getItem("userType") === "producer",
      rootSubmenuKeys: [
        "dashboards",
        "pages",
        "applications",
        "ecommerce",
        "authentication",
        "basic",
        "components",
        "changelog",
      ],
      openKeys: this.$route.meta.sidebarMap,
    };
  },
  methods: {
    handleMenuClick() {
      if (window.innerWidth < 768) {
      // A ação só ocorrerá em versões mobile
      this.$emit('toggleSidebar', true);
    }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    }
  },
};
</script>

<style>
.gradient {
  background-image: linear-gradient(-45deg, #43dacb, #2c8e84, #1c5b54, #132c4f);
  color: black;
  background-clip: text;
  -webkit-background-clip: text;

  /* Adicionado: */
  -webkit-text-fill-color: transparent;
}

@font-face {
  font-family: 'agrandir';
  src: url('/fonts/Agrandir-GrandHeavy.otf') format('otf');
}

.text-logo {
  font-family: 'agrandir', sans-serif;
  color: #fff;
  font-size: 25px;
}
</style>
