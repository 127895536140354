import Vue from 'vue'
import VueRouter from 'vue-router'
import authMiddleware from '@/middlewares/auth';

Vue.use(VueRouter)

let routes = [
	{
		path: '/',
		name: 'Home',
		redirect: '/login', // Redireciona a URL principal para a página de login
	  },
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboards/',
	},
	{
		path: '/dashboards/',
		name: 'Dashboard',
		layout: "dashboard",
		meta: {
			title: 'Default Dashboard',
			layoutClass: 'layout-profile',
			sidebarMap: ['dashboards'],
			breadcrumbs: ['Dashboards', 'Default'],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboards/Member.vue'),
	},
	{
		path: '/dashboards/crm',
		name: 'DashboardsCRM',
		layout: "dashboard",
		meta: {
			title: 'CRM',
			layoutClass: 'layout-profile',
			sidebarMap: ['dashboards'],
			breadcrumbs: ['Dashboards', 'CRM'],
		},
		component: () => import('../views/Dashboards/CRM.vue'),
	},
	{
		path: '/dashboards/member',
		name: 'DashboardsMember',
		layout: "dashboard",
		meta: {
			title: 'Member',
			layoutClass: 'layout-profile',
			sidebarMap: ['dashboards'],
			breadcrumbs: ['Dashboards', 'Member'],
		},
		component: () => import('../views/Dashboards/Member.vue'),
	},
	{
		path: '/pages/profile/profile-overview',
		name: 'ProfileOverview',
		layout: "dashboard",
		meta: {
			title: 'Overview',
			layoutClass: 'layout-profile',
			sidebarMap: ['pages', 'profile', 'profile-overview'],
			breadcrumbs: ['Pages', 'Profile', 'Overview'],
		},
		component: () => import('../views/Profile/ProfileOverview.vue'),
	},
	{
		path: '/pages/profile/all-projects',
		name: 'AllProjects',
		layout: "dashboard",
		meta: {
			title: 'All Projects',
			layoutClass: 'layout-profile',
			sidebarMap: ['pages', 'profile', 'all-projects'],
			breadcrumbs: ['Pages', 'Profile', 'All Projects'],
		},
		component: () => import('../views/Profile/AllProjects.vue'),
	},
	{
		path: '/pages/users/new-user',
		name: 'NewUser',
		layout: "dashboard",
		meta: {
			title: 'New User',
			layoutClass: 'layout-profile',
			sidebarMap: ['pages', 'users', 'new-user'],
			breadcrumbs: ['Pages', 'Users', 'New User'],
		},
		component: () => import('../views/Users/New.vue'),
	},
	{
		path: '/pages/account/settings',
		name: 'Configurações',
		layout: "dashboard",
		meta: {
			title: 'Configurações',
			sidebarMap: ['settings', 'account', 'settings'],
			breadcrumbs: ['Inicio', 'Conta', 'Configurações'],
		},
		component: () => import('../views/Account/Settings.vue'),
	},
	{
		path: '/pages/account/billing',
		name: 'Billing',
		layout: "dashboard",
		meta: {
			title: 'Billing',
			sidebarMap: ['pages', 'account', 'billing'],
			breadcrumbs: ['Pages', 'Account', 'Billing'],
		},
		component: () => import('../views/Account/Billing.vue'),
	},
	{
		path: '/pages/account/invoice',
		name: 'Invoice',
		layout: "dashboard",
		meta: {
			title: 'Invoice',
			sidebarMap: ['pages', 'account', 'invoice'],
			breadcrumbs: ['Pages', 'Account', 'Invoice'],
		},
		component: () => import('../views/Account/Invoice.vue'),
	},
	/* LISTA DE OFERTAS */
	{
		path: '/pages/offer/members/list',
		name: 'Offers',
		layout: "dashboard",
		meta: {
			title: 'Lista de Ofertas',
			layoutClass: 'layout-profile',
			sidebarMap: ['offers'],
			breadcrumbs: ['Offers'],
		},
		component: () => import('../views/Offer/Members/List.vue'),
	},
	/* DETALHES DA OFERTA */
	{
		path: '/pages/offer/members/listDetails/:offerId/:offerType',
		name: 'OfferDetails',
		layout: 'dashboard',
		meta: {
			title: 'Offer Details',
			sidebarMap: ['offers'],
			breadcrumbs: ['Offers', 'Offer Details'],
		},
		component: () => import('../views/Offer/Members/ListDetails.vue'),
	},
	/* DETALHES DAS TRILHAS FORUM */
	{
		path: '/pages/offer/members/ListTrailProducer/:producer',
		name: 'TrailProducer',
		layout: 'dashboard',
		meta: {
			title: 'Offer Details',
			sidebarMap: ['offers'],
			breadcrumbs: ['Offers', 'Offer Details'],
		},
		component: () => import('../views/Offer/Members/ListTrailProducer.vue'),
	},
	{
		path: '/pages/projects/timeline',
		name: 'Timeline',
		layout: "dashboard",
		meta: {
			title: 'Timeline',
			sidebarMap: ['pages', 'projects', 'timeline'],
			breadcrumbs: ['Pages', 'Projects', 'Timeline'],
		},
		component: () => import('../views/Projects/Timeline.vue'),
	},
	{
		path: '/pages/pricing',
		name: 'Pricing',
		meta: {
			layoutClass: 'layout-pricing',
			title: 'Pricing',
		},
		component: () => import('../views/Pricing.vue'),
	},
	{
		path: '/pages/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
			title: 'RTL',
			sidebarMap: ['pages', 'rtl'],
			breadcrumbs: ['Pages', 'RTL'],
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/pages/charts',
		name: 'Charts',
		layout: "dashboard",
		meta: {
			layoutClass: 'dashboard',
			title: 'Charts',
			sidebarMap: ['pages', 'charts'],
			breadcrumbs: ['Pages', 'Charts'],
		},
		component: () => import('../views/Charts.vue'),
	},
	{
		path: '/pages/alerts',
		name: 'Alerts',
		layout: "dashboard",
		meta: {
			layoutClass: 'dashboard',
			title: 'Alerts',
			sidebarMap: ['pages', 'alerts'],
			breadcrumbs: ['Pages', 'Alerts'],
		},
		component: () => import('../views/Alerts.vue'),
	},
	{
		path: '/pages/notifications',
		name: 'Notifications',
		layout: "dashboard",
		meta: {
			layoutClass: 'dashboard',
			title: 'Notifications',
			sidebarMap: ['pages', 'notifications'],
			breadcrumbs: ['Pages', 'Notifications'],
		},
		component: () => import('../views/Notifications.vue'),
	},
	{
		path: '/applications/calendar',
		name: 'Calendar',
		layout: "dashboard",
		meta: {
			title: 'Calendar',
			sidebarMap: ['applications'],
			breadcrumbs: ['Applications', 'Calendar'],
		},
		component: () => import('../views/Applications/Calendar.vue'),
	},
	{
		path: '/applications/kanban',
		name: 'Kanban',
		layout: "dashboard",
		meta: {
			title: 'Kanban',
			sidebarMap: ['applications'],
			breadcrumbs: ['Applications', 'Kanban'],
		},
		component: () => import('../views/Applications/Kanban.vue'),
	},
	{
		path: '/applications/wizard',
		name: 'Wizard',
		layout: "dashboard",
		meta: {
			title: 'Wizard',
			sidebarMap: ['applications'],
			breadcrumbs: ['Applications', 'Wizard'],
		},
		component: () => import('../views/Applications/Wizard.vue'),
	},
	{
		path: '/applications/datatables',
		name: 'DataTables',
		layout: "dashboard",
		meta: {
			title: 'DataTables',
			sidebarMap: ['applications'],
			breadcrumbs: ['Applications', 'DataTables'],
		},
		component: () => import('../views/Applications/DataTables.vue'),
	},
	{
		path: '/ecommerce/products/new-product',
		name: 'New Product',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'New Product',
			sidebarMap: ['ecommerce', 'products', 'new-product'],
			breadcrumbs: ['Ecommerce', 'Products', 'New Product'],
		},
		component: () => import('../views/Ecommerce/Products/NewProduct.vue'),
	},
	{
		path: '/ecommerce/products/edit-product',
		name: 'Edit Product',
		layout: "dashboard",
		meta: {
			title: 'Edit Product',
			sidebarMap: ['ecommerce', 'products', 'edit-product'],
			breadcrumbs: ['Ecommerce', 'Products', 'Edit Product'],
		},
		component: () => import('../views/Ecommerce/Products/EditProduct.vue'),
	},
	{
		path: '/ecommerce/products/product-page',
		name: 'Product Page',
		layout: "dashboard",
		meta: {
			title: 'Product Page',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Ecommerce', 'Products', 'Product Page'],
		},
		component: () => import('../views/Ecommerce/Products/ProductPage.vue'),
	},
	{
		path: '/ecommerce/orders/orders-list',
		name: 'Orders List',
		layout: "dashboard",
		meta: {
			title: 'Orders List',
			sidebarMap: ['ecommerce', 'orders', 'orders-list'],
			breadcrumbs: ['Ecommerce', 'Orders', 'Orders List'],
		},
		component: () => import('../views/Ecommerce/Orders/OrdersList.vue'),
	},
	{
		path: '/ecommerce/orders/orders-details',
		name: 'Orders Details',
		layout: "dashboard",
		meta: {
			title: 'Orders Details',
			sidebarMap: ['ecommerce', 'orders', 'orders-details'],
			breadcrumbs: ['Ecommerce', 'Orders', 'Orders Details'],
		},
		component: () => import('../views/Ecommerce/Orders/OrdersDetails.vue'),
	},
	{
		path: '/authentication/sign-up/basic',
		name: 'Basic Sign Up',
		meta: {
			layoutClass: 'layout-sign-up',
			title: 'Basic Sign Up',
			sidebarMap: ['authentication', 'sign-up', 'basic'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Basic'],
		},
		component: () => import('../views/Authentication/Sign-Up/Basic.vue'),
	},
	{
		path: '/authentication/sign-up/cover',
		name: 'Cover Sign Up',
		meta: {
			layoutClass: 'layout-sign-up-cover',
			title: 'Cover Sign Up',
			sidebarMap: ['authentication', 'sign-up', 'cover'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Cover'],
		},
		component: () => import('../views/Authentication/Sign-Up/Cover.vue'),
	},
	{
		path: '/authentication/sign-up/illustration',
		name: 'Illustration Sign Up',
		meta: {
			layoutClass: 'layout-sign-up-illustration',
			title: 'Illustration Sign Up',
			sidebarMap: ['authentication', 'sign-up', 'illustration'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Illustration'],
			nofooter: true,
		},
		component: () => import('../views/Authentication/Sign-Up/Illustration.vue'),
	},
	{
		path: '/business/login',
		name: 'Business Login',
		meta: {
			layoutClass: 'layout-sign-up-illustration',
			title: 'Business Login',
			sidebarMap: ['authentication', 'sign-up', 'illustration'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Illustration'],
			nofooter: true,
		},
		component: () => import('../views/Authentication/Sign-In/Business.vue'),
	},
	{
		path: '/login',
		name: 'Member Login',
		meta: {
		  layoutClass: 'layout-sign-up-cover',
		  title: 'Member Login',
		  sidebarMap: ['authentication', 'sign-up', 'illustration'],
		  breadcrumbs: ['Authentication', 'Sign Up', 'Illustration'],
		  nofooter: true,
		},
		component: () => import('../views/Authentication/Sign-In/Member.vue'),
	  },
	{
		path: '/register-producer',
		name: 'Registrar Produtor',
		meta: {
			layoutClass: 'layout-sign-up-cover',
			title: 'Member Login',
			sidebarMap: ['authentication', 'sign-up', 'illustration'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Illustration'],
			nofooter: true,

		},
		component: () => import('../views/Authentication/Sign-In/Productor.vue'),
	},
	{
		path: '/register-member',
		name: 'Registrar Membro',
		meta: {
			layoutClass: 'layout-sign-up-cover',
			title: 'Member Login',
			sidebarMap: ['authentication', 'sign-up', 'illustration'],
			breadcrumbs: ['Authentication', 'Sign Up', 'Illustration'],
			nofooter: true,

		},
		component: () => import('../views/Authentication/Sign-In/Register-Member.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	{
		path: '/business/offers',
		name: 'Business Offers',
		layout: "dashboard",
		meta: {
			title: 'Ofertas',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Ofertas', 'Todas'],
		},
		component: () => import('../views/Business/Offer/List.vue'),
	},
	{
		path: '/business/offers/new',
		name: 'New Offers',
		layout: "dashboard",
		meta: {
			title: 'Ofertas',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Ofertas', 'Nova'],
		},
		component: () => import('../views/Business/Offer/New.vue'),
	},
	{
		path: '/business/announcer',
		name: 'Business Announcers',
		layout: "dashboard",
		meta: {
			title: 'Anunciantes',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Todos'],
		},
		component: () => import('../views/Business/Announcer/List.vue'),
	},
	{
		path: '/business/announcer/new',
		name: 'New Announcer',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Novo',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Business/Announcer/New.vue'),
	},
	{
		path: '/productor/contents',
		name: 'Minhas Produções',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Produções',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Productor/Contents.vue'),
	},
	{
		path: '/member/contents',
		name: 'Minhas Conteúdos',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Conteúdos',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Offer/Members/Contents.vue'),
	},
	{
		path: '/productor/contents/new',
		name: 'Novo conteúdo',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Novo',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Productor/New.vue'),
	},
	{
		path: '/indications',
		name: 'Indications',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Novo',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Indications/IndicationsList.vue'),
	},
	{
		path: '/play/:offerId',
		name: 'Play',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Novo',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Offer/Members/Player.vue'),
	},
	{
		path: '/productor/financial',
		name: 'Financeiro',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Financeiro',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Productor/Financial.vue'),
	},
	{
		path: '/productor/reports',
		name: 'Relatórios',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			title: 'Relatórios',
			sidebarMap: ['ecommerce', 'products', 'product-page'],
			breadcrumbs: ['Business', 'Anunciantes', 'Novo'],
		},
		component: () => import('../views/Productor/Reports.vue'),
	},


]

// Adding layout property from each route to the meta
// object so it can be accessed later.
// Função para adicionar layout à rota e suas filhas
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {};
	route.meta.layout = route.layout || parentLayout;
  
	if (route.children) {
	  route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
	}
	return route;
  }
  
  routes = routes.map((route) => addLayoutToRoute(route));
  
  const router = new VueRouter({
	mode: 'history', // Altere para 'history' para remover o '#' da URL
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
	  if (to.hash) {
		return {
		  selector: to.hash,
		  behavior: 'smooth',
		}
	  }
	  return {
		x: 0,
		y: 0,
		behavior: 'smooth',
	  }
	}
  })
  
  router.beforeEach(authMiddleware);

export default router
