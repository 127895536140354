import axios from 'axios';

function setBaseUrl (baseUrl) {
   axios.defaults.baseURL = baseUrl
}

axios.interceptors.request.use(
   config => {

      if (!config.headers.Authorization) {
         const token = window.localStorage.getItem('accessToken');

         if (token) {
            config.headers.Authorization = `Bearer ${token}`;
         }
      }

      return config;
   },
   error => Promise.reject(error)
);

function request (path, params) {
   return new Promise((resolve, reject) => {
      // console.log('REQUEST PARAMS: '+path, params);

      if (typeof params === 'object' && params !== null) {
         params = { qp: JSON.stringify(params) };
      } else if (params == null) {
         params = null
      } else {
         params = { qp: JSON.stringify({ filterBy: { text: params } }) };
      }

      axios.get(path, { params })
         .then(response => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(failHandler(err, true));
         });
   });
}

function post (path, params, notJson = false) {
   let data;
   if(notJson){
      data = params;
   }else{
      data = JSON.parse(JSON.stringify(params)); //to clear vue observables
   }

   return new Promise((resolve, reject) => {
      axios.post(path, data)
         .then(response => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(failHandler(err));
         });
   });
}

function put (path, params) {
   let data = JSON.parse(JSON.stringify(params)); //to clear vue observables

   return new Promise((resolve, reject) => {
      axios.put(path, data)
         .then(response => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(failHandler(err));
         });
   });
}

function patch(path, params) {
   let data = JSON.parse(JSON.stringify(params)); //to clear Vue observables

   return new Promise((resolve, reject) => {
      axios.patch(path, data)
         .then(response => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(failHandler(err));
         });
   });
}

function destroy (path) {
   return new Promise((resolve, reject) => {
      axios.delete(path)
         .then(response => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(failHandler(err, true));
         });
   });
}

function failHandler (error, req) {
   let ret = null;
   let msg = (req) ? '' : '';

   function msgParser (msg) {
      return window.getApp.$t(msg);
   }


   if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      ret = error.response.data;
      console.log('data', error.response.data);
      console.log('status', error.response.status);
      console.log('header', error.response.headers);
      // msg += (msgParser(error.response.data.msg) || error.response.statusText);
      console.log('1erro');
      this.$notification.open({
         class: 'ant-notification-' + 'primary',
         message: 'Notification Title',
         description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
         duration: 0,
      });
   } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('request', error.request);
   } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      msg += String.fromCharCode(10) + error.message;
   }
   // console.log('config', error.config);

   window.getApp.$notifyUser({
      message: msg,
      type: 'error'
   });

   return ret;
}

function rawGet (fullPath) {
   return axios.get(fullPath);
}

function rawPost (fullPath, params) {
   return axios.post(fullPath, params);
}


export default {
   setBaseUrl,
   request,
   post,
   put,
   patch, 
   destroy,
   rawGet,
   rawPost
}
