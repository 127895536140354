export default function (to, from, next) {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        console.log(to);
        if (to.path !== '/login' && to.path !== '/business/login') {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
}