import Vue from 'vue'

Vue.mixin({
   beforeCreate() {
      const options = this.$options;
      if (options.api)
         this.$api = options.api;
      else if (options.parent && options.parent.$api)
         this.$api = options.parent.$api;
   }
});

//Import all api services here
import dataService from './data.services';
import authService from './auth.services';
import httpService from './http.service';


//Exporting the API
export default {
   dataService,
   authService,
   get: httpService.rawGet,
   post: httpService.rawPost,
   setBaseUrl: httpService.setBaseUrl
};








